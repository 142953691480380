import { PUBLIC_SENTRY_TRACING_ENABLED, PUBLIC_VERCEL_ENV } from "$env/static/public";
import * as Sentry from "@sentry/sveltekit";
import type { Integration } from "@sentry/types";

export const TRACING_ENABLED = PUBLIC_SENTRY_TRACING_ENABLED === "true";

export function sharedConfig<T extends Sentry.NodeOptions | Sentry.BrowserOptions>(dsn: string) {
  const integrations: Integration[] = [
    Sentry.captureConsoleIntegration({
      levels: ["error", "assert"],
    }),
    Sentry.extraErrorDataIntegration(),
  ];
  const config = {
    dsn,
    environment: PUBLIC_VERCEL_ENV,

    attachStacktrace: true,

    beforeSend(event, hint) {
      const error = hint.originalException as { message?: string } | undefined;
      if (error?.message?.match(/Amplitude Logger/) !== null) {
        console.log("Ignoring Amplitude logger errors:", event);
        return null;
      }
      if (event.exception?.values?.[0]?.mechanism?.handled === true) {
        console.log("Handled error:", event);
        return null;
      }
      return event;
    },
  } as T;

  if (TRACING_ENABLED) {
    // We recommend adjusting this value in production, or using `tracesSampler`
    // for finer control
    // 0.1 means 10% of traces are captured
    config.tracesSampleRate = 0.1;
    // Profiling sample rate is relative to tracesSampleRate
    config.profilesSampleRate = 1.0;
  }

  return { config, integrations };
}
