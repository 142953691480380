import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93'),
	() => import('./nodes/94'),
	() => import('./nodes/95'),
	() => import('./nodes/96'),
	() => import('./nodes/97'),
	() => import('./nodes/98'),
	() => import('./nodes/99'),
	() => import('./nodes/100'),
	() => import('./nodes/101'),
	() => import('./nodes/102'),
	() => import('./nodes/103'),
	() => import('./nodes/104'),
	() => import('./nodes/105'),
	() => import('./nodes/106'),
	() => import('./nodes/107'),
	() => import('./nodes/108'),
	() => import('./nodes/109'),
	() => import('./nodes/110'),
	() => import('./nodes/111'),
	() => import('./nodes/112'),
	() => import('./nodes/113'),
	() => import('./nodes/114'),
	() => import('./nodes/115'),
	() => import('./nodes/116'),
	() => import('./nodes/117'),
	() => import('./nodes/118'),
	() => import('./nodes/119'),
	() => import('./nodes/120'),
	() => import('./nodes/121')
];

export const server_loads = [0,2,6];

export const dictionary = {
		"/(public)": [79],
		"/(public)/about-us": [80],
		"/(public)/add-deck": [81],
		"/(admin)/admin/extra-mana/add": [~20,[2,3]],
		"/(admin)/admin/orders/buyer/[order_id]": [~23,[2,4]],
		"/(admin)/admin/orders/order_issue_report": [~24,[2,4]],
		"/(admin)/admin/orders/order_issue_report/[id]": [~25,[2,4]],
		"/(admin)/admin/orders/ratings/[profile_id]": [~26,[2,4]],
		"/(admin)/admin/orders/seller-stats": [~27,[2,4]],
		"/(admin)/admin/orders/seller-stats/[profile_id]": [~28,[2,4]],
		"/(admin)/admin/orders/unfulfilled": [~29,[2,4]],
		"/(admin)/admin/orders/[seller_order_id]": [~21,[2,4]],
		"/(admin)/admin/orders/[seller_order_id]/report": [~22,[2,4]],
		"/(admin)/admin/payouts/edit": [~30,[2,5]],
		"/(admin)/admin/payouts/history": [~31,[2,5]],
		"/(admin)/admin/payouts/issue": [~32,[2,5]],
		"/(admin)/admin/payouts/pending": [~33,[2,5]],
		"/(admin)/admin/payouts/seller/[normalized_username]": [~34,[2,5]],
		"/(admin)/admin/supply/health": [~35,[2]],
		"/(admin)/admin/supply/health/[format]": [~36,[2]],
		"/(public)/affiliates": [82],
		"/api/docs/ccgseller": [117],
		"/api/docs/v1": [118],
		"/auth": [~119],
		"/auth/confirm": [~120],
		"/(public)/browse_sealed": [83,[11]],
		"/(public)/cards": [86,[13]],
		"/(public)/cards/set/[set_code]/[[set_name]]": [87,[13,14]],
		"/(public)/card/[card_slug]": [84],
		"/(public)/card/[set_code]/[card_number]/[[card_name]]": [85,[12]],
		"/(public)/cart": [88,[15]],
		"/(public)/cart/checkout": [89,[15]],
		"/(public)/cart/optimizer": [90,[15]],
		"/(public)/deals": [91],
		"/(public)/mail": [92],
		"/(public)/mail/error": [94],
		"/(public)/mail/notfound": [95],
		"/(public)/mail/[template]/[content]": [~93],
		"/maintenance": [121],
		"/(public)/mtg": [96],
		"/(public)/mtg/set/[set_code]": [97],
		"/(public)/mtg/set/[set_code]/[slug]": [98],
		"/(public)/optimizer": [99],
		"/(authed)/packing-slip/[order_id]": [37,[6]],
		"/(public)/privacy": [100],
		"/(authed)/profile/change-email": [38,[6,7]],
		"/(authed)/profile/change-password": [39,[6,7]],
		"/(authed)/profile/notifications": [~40,[6,7]],
		"/(public)/racist-depictions-policy": [101],
		"/(public)/redirect-after-login": [~102],
		"/(public)/reset-password": [~103],
		"/(public)/return-policy": [104],
		"/(public)/sealed/[set_code]/[[slug]]": [105,[16]],
		"/(public)/seller-info": [106],
		"/(authed)/seller": [41,[6,8]],
		"/(authed)/seller/integrations": [42,[6,8,9]],
		"/(authed)/seller/integrations/ccgseller": [~43,[6,8,9]],
		"/(authed)/seller/integrations/crystalcommerce": [~44,[6,8,9]],
		"/(authed)/seller/integrations/manapool-api": [~45,[6,8,9]],
		"/(authed)/seller/integrations/shopify": [46,[6,8,9]],
		"/(authed)/seller/inventory": [47,[6,8]],
		"/(authed)/seller/inventory/add/[[item_id]]": [48,[6,8]],
		"/(authed)/seller/inventory/edit/[item_id]": [49,[6,8]],
		"/(authed)/seller/inventory/import": [~50,[6,8]],
		"/(authed)/seller/orders": [51,[6,8]],
		"/(authed)/seller/orders/fulfilled": [54,[6,8]],
		"/(authed)/seller/orders/open": [55,[6,8]],
		"/(authed)/seller/orders/[order_id]": [~52,[6,8]],
		"/(authed)/seller/orders/[order_id]/report": [~53,[6,8]],
		"/(authed)/seller/payouts": [~56,[6,8]],
		"/(authed)/seller/payouts/[payout_id]": [~57,[6,8]],
		"/(authed)/seller/pricing": [~58,[6,8]],
		"/(authed)/seller/profile": [59,[6,8]],
		"/(authed)/seller/sales": [60,[6,8]],
		"/(authed)/seller/sales/[id]": [61,[6,8]],
		"/(authed)/seller/stats": [~62,[6,8]],
		"/(authed)/seller/stats/ratings": [~63,[6,8]],
		"/(authed)/settings": [64,[6,10]],
		"/(authed)/settings/addresses": [65,[6,10]],
		"/(authed)/settings/affiliates": [66,[6,10]],
		"/(authed)/settings/billing": [67,[6,10]],
		"/(authed)/settings/change-email": [68,[6,10]],
		"/(authed)/settings/change-password": [69,[6,10]],
		"/(authed)/settings/condition": [70,[6,10]],
		"/(authed)/settings/extra-mana": [71,[6,10]],
		"/(authed)/settings/notifications": [72,[6,10]],
		"/(authed)/settings/orders": [73,[6,10]],
		"/(authed)/settings/orders/report/[order_id]": [~75,[6,10]],
		"/(authed)/settings/orders/suborder/[seller_order_id]": [~76,[6,10]],
		"/(authed)/settings/orders/[order_id]": [~74,[6,10]],
		"/(authed)/settings/referrals": [~77,[6,10]],
		"/(public)/set/all_sets": [108],
		"/(public)/set/[set_code]/[[slug]]": [107,[17]],
		"/(public)/shopify": [~110],
		"/(public)/shop/[normalized_username]": [109,[18,19]],
		"/(public)/signin": [~111],
		"/(public)/signup": [~112],
		"/(authed)/support": [~78,[6]],
		"/(public)/terms": [113],
		"/(public)/thank-you/[id]": [~114],
		"/(public)/top_cards": [~115],
		"/(public)/top_cards/[format]": [~116]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';