import { PUBLIC_VERCEL_ENV } from "$env/static/public";
import { datadogLogs } from "@datadog/browser-logs";

export function initDatadog({ clientToken, site }: { clientToken: string; site: string }) {
  if (!clientToken || !site) {
    return;
  }
  datadogLogs.init({
    clientToken,
    site,
    forwardErrorsToLogs: true,
    forwardConsoleLogs: "all",
    env: PUBLIC_VERCEL_ENV,
  });
}
