import { dev } from "$app/environment";
import { PUBLIC_SUPABASE_URL } from "$env/static/public";
import * as Sentry from "@sentry/sveltekit";
import { supabaseIntegration } from "@supabase/sentry-js-integration";
import { SupabaseClient } from "@supabase/supabase-js";
import type { HandleClientError } from "@sveltejs/kit";
import { sharedConfig, TRACING_ENABLED } from "./sentryUniversal";

export function initSentry(dsn: string) {
  if (dev) return;
  if (Sentry.getClient() !== undefined) return; // already initialized

  const { config, integrations } = sharedConfig<Sentry.BrowserOptions>(dsn);

  integrations.push(
    supabaseIntegration(SupabaseClient, Sentry, {
      tracing: TRACING_ENABLED,
      breadcrumbs: true,
      errors: true,
    }),
    Sentry.browserTracingIntegration({
      shouldCreateSpanForRequest: (url) => {
        return !url.startsWith(`${PUBLIC_SUPABASE_URL}/rest`);
      },
    }),
  );
  if (TRACING_ENABLED) {
    config.replaysSessionSampleRate = 0.05;
    config.replaysOnErrorSampleRate = 1.0;
    integrations.push(Sentry.browserProfilingIntegration(), Sentry.replayIntegration());
  }

  Sentry.init({ ...config, integrations });
}

export function wrapHandleError(handler: HandleClientError): HandleClientError {
  if (dev) return handler;
  return Sentry.handleErrorWithSentry(handler);
}
